import { render, staticRenderFns } from "./AYP.vue?vue&type=template&id=4a0c8732&scoped=true"
import script from "./AYP.vue?vue&type=script&lang=js"
export * from "./AYP.vue?vue&type=script&lang=js"
import style0 from "./AYP.vue?vue&type=style&index=0&id=4a0c8732&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a0c8732",
  null
  
)

export default component.exports